<template>
  <div class="edit">
    <el-dialog
      title="放款"
      :visible.sync="isShow"
      :width="width"
      :show-close="false"
      :modal="false"
      :close-on-click-modal="false"
      @open="openDialog"
    >
      <el-form
       ref="form"
        :model="form" label-width="80px" class="form" hide-required-asterisk>
        <el-form-item label="放款密码" prop="riskPass">
            <el-input v-model="form.riskPass" ></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        riskPass:''
      },
      width: '40%'

    };
  },
  computed: {
    // title() {
    //   let { username = '', mobile = '' } = this.editData;
    //   return username ? '编辑用户 -' + username : '编辑用户 - ' + mobile;
    // }
  },
  methods: {
    cancel() {
      this.$emit('cancelL');
    },
    submit() {
        // // console.log(this.form.amount,this.form.riskPass, this.form)
        let form ={
          loanPassword: this.form.riskPass
        }

        this.$emit('submitL', form);
        this.$refs['form'].resetFields();
    },
    openDialog() {
        if(document.documentElement.clientWidth < 480){
          this.width = '80%'
        }
        this.$nextTick(() => {
        // this.form.exam = this.editData.status;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.edit {
  .form {
    width: 70%;
    margin: 0 auto;
    text-align: left;
    .el-select {
      width: 100%;
    }
  }
}
</style>
