import API from "../../api/index";

export default {
  // 获取好友列表
  getUserList: param => {
    return API.requestGet({
      url: "friend/getList",
      auth: true,
      params:{
        ...param
      }
    });
  },
  // 获取黑名单列表
  getBlackList: param => {
    return API.requestGet({
      url: "friend/getList",
      auth: true,
      params: {
        status: -1,
        ...param
      }
    });
  },
  // 客服选项
  getCustomerList: params => {
    return API.requestGet({
      url: "link/getCustomerList",
      auth: true,
      params
    });
  },
  // 渠道选项
  getChannels: params => {
    return API.requestGet({
      url: "link/getLinkForRole",
      auth: true,
      params
    });
  },
  // 移出黑名单
  deleteBlackUser: param => {
    return API.requestPost({
      url: "friend/operationFriendList",
      data: {
        status: 0,
        ...param
      },
      auth: true
    });
  },
  // 添加到黑名单
  addBlackUser: param => {
    return API.requestPost({
      url: "friend/operationFriendList",
      data: {
        status: -1,
        ...param
      },
      auth: true
    });
  },
  // 审核
  check: params => {
    return API.requestPost({
      data: params,
      url: "friend/check"
    });
  },
  // 分配客服选项
  customerOpts: () => {
    return API.requestGet({
      params: { pageSize: -1, pageNum: 0 },
      url: "son/getList"
    });
  },
  //用户报告的备注
  remark:params => {
    return API.requestPost({
      data: params,
      url: "friend/editMemo"
    });
  },
  //用户报告的备注历史记录
  remarkRecord:params => {
    return API.requestPost({
      data: params,
      url: "friend/selectMemoRecord"
    });
  },
  //获取拒绝原因
  getRefuseOptions: params => {
    return API.requestPost(
      {
        url: "/api/v2/basic/queryBasic",
        data:{
          keys: "sys_reject_reason"
        }
      },
      false
    );
  },
  //共享黑名单
  getUserblacklist: params => {
    return API.requestGet(
      {
        url: "/api/common/report/basic/userblacklist",
        params
      },
      false
    );
  },
};
